import { createContext, useState } from "react"

export const SettingsContext = createContext();

export const SettingsProvider = ({children}) => {
    const [selectedTheme, setSelectedTheme] = useState("default");

    return (
        <SettingsContext.Provider value={{selectedTheme, setSelectedTheme}}>
            {children}
        </SettingsContext.Provider>
    )
}