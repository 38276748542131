import { Divider, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'

function Failback() {
  const theme = useTheme();
  return (
    <Paper elevation={12} sx={{m: "auto", width: "fit-content", p: 2}}>
      <Typography variant='h6' sx={{textAlign: "center"}}>
        Diese Seite befindet sich noch im Aufbau
      </Typography>                
      <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3}}/>
    </Paper>
  )
}

export default Failback