import { Alert, AlertTitle, Box, Button, Divider, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Paper, Select, Switch, Typography, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { SettingsContext } from './hooks/GlobalSettings'
import { ApiContext } from './hooks/HellApi';
import { ErrorOutline, Menu } from '@mui/icons-material';

const colors = {
    "default": "#2A3056",
    "dark": "rgb(49, 49, 49)",
    "hellish": "#450901"
}

function Settings() {
    const [userStore, setUserStore] = useState({});
    const {userData, post, updateUserData} = useContext(ApiContext);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const theme = useTheme();

    useEffect(()=>{
        if (!userData) return;
        setUserStore(userData.store);
    }, [userData]);

    async function save(){
        setSaving(true);
        const result = await post("/user/me/store/update", {store: JSON.stringify(userStore)});
        if (result.success){
            await updateUserData();
        }else{
            if (result.response.errorText && result.response.errorText === "store_invalid"){
                setError("Bug: Setting-Store wurde mit invalidem Format übergeben. Server konnte die Anfrage nicht bearbeiten. Bitte gib Sam Bescheid");
            }else{
                setError(result.response.data??result.response.errorText??result.response.status_string);
            }
        }
        setSaving(false)
    }
    if (!userData) return;
  return (
    <Box>
        <Paper sx={{width: "fit-content", m: "auto", p: 1, mb: 2}}>
            <Typography variant='h4' sx={{textAlign: "center", color: theme.palette.primary[300]}}>
                Einstellungen
            </Typography>
            <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3, mb: 1}}/>
        </Paper>
        <Box sx={{display: "flex", flexDirection: {md:"row",xs:"column"}, width: "100%", justifyContent: "center"}}>
            <Paper sx={{display: "flex", flexDirection: "column", mx: "auto", p: 2, width: "fit-content", minWidth: "250px", mr: {md: 3, xs: "auto"}, mb: {md: 0, xs: 2}}}>
                <Typography variant='h6' sx={{textAlign: "center", color: theme.palette.primary[300]}}>
                    Theme
                </Typography>
                <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3, mb: 1}}/>
                <FormControl fullWidth sx={{mt: 1}}>
                    <InputLabel id="theme-selector-label">Theme</InputLabel>
                    <Select
                        labelId='theme-selector-label'
                        id='theme-selector'
                        value={userStore.theme??"default"}
                        label="Theme"
                        onChange={(e)=>{
                            setUserStore(prev => ({...prev, theme: e.target.value}))
                        }}
                        sx={{backgroundColor: colors[userStore.theme??"default"]}}
                    >
                        <MenuItem value={"default"} sx={{backgroundColor: colors.default}}>Neopolis</MenuItem>
                        <MenuItem value={"dark"} sx={{backgroundColor: colors.dark}}>Dunkel</MenuItem>
                        <MenuItem value={"hellish"} sx={{backgroundColor: colors.hellish}}>Höllisch</MenuItem>
                    </Select>
                </FormControl>              
                <FormControl fullWidth sx={{mt: 2}}>
                    <InputLabel id="font-selector-label">Schriftart</InputLabel>
                    <Select
                        labelId="font-selector-label"
                        id="font-selector"
                        value={userStore.font??"Lucida Console, Courier New, monospace"}
                        label="Schriftart"
                        onChange={(e)=>{
                            setUserStore(prev => ({...prev, font: e.target.value}))
                        }}
                        sx={{fontFamily: userStore.font??"Lucida Console, Courier New, monospace"}}
                    >
                        <MenuItem value={"Lucida Console, Courier New, monospace"} sx={{fontFamily: "Lucida Console, Courier New, monospace"}}>Lucida</MenuItem>
                        <MenuItem value={"Roboto,Helvetica,Arial,sans-serif"} sx={{fontFamily: "Roboto,Helvetica,Arial,sans-serif"}}>Roboto</MenuItem>
                        <MenuItem value={"monaco"} sx={{fontFamily: "monaco"}}>Monaco</MenuItem>
                        <MenuItem value={"Arial, Helvetica, sans-serif"} sx={{fontFamily: "Arial, Helvetica, sans-serif"}}>Arial</MenuItem>
                    </Select>
                </FormControl>
            </Paper>
            <Paper sx={{display: "flex", flexDirection: "column", p: 2, width: "fit-content", height: "fit-content", m: "auto", mb: {md: 0, xs: 2}}}>
                <Typography variant='h6' sx={{textAlign: "center", color: theme.palette.primary[300]}}>
                    Logo
                </Typography>
                <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3,}}/>
                <FormGroup sx={{display: "flex", flexDirection: "column"}}>
                    <FormControlLabel control={<Switch checked={!!userStore.disableMalfunktion} onChange={(e)=>{setUserStore(prev => ({...prev, disableMalfunktion: e.target.checked}))}}/>} label="Fehlfunktion deaktivieren"/>
                    <FormControlLabel control={<Switch checked={!!userStore.disableFlicker} onChange={(e)=>{setUserStore(prev => ({...prev, disableFlicker: e.target.checked}))}}/>} label="Flickern deaktivieren"/>
                </FormGroup>
            </Paper>
            <Paper sx={{width: "fit-content", ml: 3, height: "fit-content", m: "auto"}}>
                <Button variant='contained' size='large' sx={{m: 1}} disabled={userStore===userData.store||saving} onClick={()=>{save()}}>
                    {saving?"Speichert...":userStore !== userData.store? "Speichern":"Gespeichert"}
                </Button>
            </Paper>
        </Box>
        {error&&
            <Box sx={{m: "auto", width: "50%", mt: 2}}>
                <Alert  icon={<ErrorOutline/>} severity='error'>
                    <AlertTitle sx={{textDecoration: "underline", fontWeight: "bold"}}>Fehler beim Speichern:</AlertTitle>
                    {error}
                </Alert>
            </Box>}
    </Box>
  )
}

export default Settings