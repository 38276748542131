import { Avatar, Box, Button, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect, useContext, useReducer, useRef } from 'react'
import {useNavigate} from 'react-router-dom';
import Logo from '../background.jpg';
import { ApiContext } from '../hooks/HellApi';
import { Edit, Image } from '@mui/icons-material';

function CharacterBox({characterData, setCharacterData, editable, minimal}) {
    const navigate = useNavigate();
    const [imageKey, setImageKey] = useState(Date.now());
    const {userData, getBasePath, post, get} = useContext(ApiContext);
    const theme = useTheme();
    const [username, setUsername] = useState();

    const fileInputRef = useRef(null);
    async function updateImage(e){
        const file = e.target.files[0];
        if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/webp"){
            return alert("Das Bild muss im Format .png oder .jpg sein.");
        }
        if (file.size > 5000000){
            return alert("Das Bild darf maximal 5 MB groß sein.");
        }
        const result = await post("/images/character/mine", {image: file, id: characterData.id});
        if (result.success){
            setImageKey(prev=>prev+1);
        }
        console.log(result);
    }

    useEffect(()=>{
        getUsername();
    }, [characterData.userid]);

    function handleImageClick(){
        if (!editable) return;
        fileInputRef.current.click();
    }

    async function getUsername(){
        const result = await get(`/user/${characterData.userid}/username`);
        console.log(result.response.data);
        setUsername(result.response.data);
    }
  return (
    <Box onClick={()=>{
        if (!minimal) return;
        if (userData.id === characterData.userid){
            navigate(`/myCharacter/${characterData.id}`);
        }else{
            navigate(`/characters/${characterData.id}`);
        }
    }} sx={[{width: "fit-content", minWidth: "fit-content", }, minimal&&{":hover": {cursor: "pointer", filter: "grayscale(33%)", WebkitFilter: "grayscale(33%)"}}]}>
          <Paper elevation={12} sx={{p: 2, display: "flex", flexDirection: "column", width: "fit-content", maxWidth: "350px"}}>
            <Box sx={{display: "flex", flexDirection: "column", minWidth: "fit-content", maxWidth: "350px"}}>
              <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300], textAlign: "center", flexGrow: 1}} variant='h5'>
                {characterData.name} "{characterData.nick}" {characterData.surname}
              </Typography>
              <Box key={imageKey} sx={[{aspectRatio: 4/5, minWidth: "300px", border: `3px solid ${theme.palette.primary[300]}80`, position: "relative"}, editable&&{":hover": {filter: "grayscale(33%)", WebkitFilter: "grayscale(33%)", cursor: "pointer", "& *": {filter: "grayscale(33%)", WebkitFilter: "grayscale(33%)"}}}]}>
                  <img onClick={()=>{
                      handleImageClick();
                  }} style={{width: "100%", height: "100%"}}  src={`${getBasePath()}/images/character/${characterData.id}?${imageKey}`}/>
                  {editable&&<Image sx={{position: "absolute", top: 0, bottom: 0, m: "auto", left: 0, right: 0, fontSize: 64, color: theme.palette.primary[300]}} onClick={()=>{handleImageClick()}}/>}
                  <input type='file' ref={fileInputRef} style={{display: "none"}} onChange={updateImage}/>
              </Box>
              
            </Box>
            <TableContainer>
              <Table size='small' sx={{"& td": {borderBottomColor: `${theme.palette.primary[300]}80`}}}>
                <TableBody>

                <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Spieler
                      </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                          {username}
                        </Typography>
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Vorname/n
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.name} onChange={(e)=>{setCharacterData(prev => ({...prev, name: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.name}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Nachname
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.surname} onChange={(e)=>{setCharacterData(prev => ({...prev, surname: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.surname}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Spitzname/n
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.nick} onChange={(e)=>{setCharacterData(prev => ({...prev, nick: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.nick}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Alter
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.age} onChange={(e)=>{setCharacterData(prev => ({...prev, age: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.age}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Größe
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.height} onChange={(e)=>{setCharacterData(prev => ({...prev, height: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.height}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Augenfarbe
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.eye_color} onChange={(e)=>{setCharacterData(prev => ({...prev, eye_color: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.eye_color}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Haarfarbe
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.hair_color} onChange={(e)=>{setCharacterData(prev => ({...prev, hair_color: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.hair_color}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Beruf
                      </Typography>
                    </TableCell>
                    <TableCell>
                    {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.job} onChange={(e)=>{setCharacterData(prev => ({...prev, job: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.job}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                        Wohnort
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {editable?
                        <TextField fullWidth variant='standard' size='small' margin='none' hiddenLabel value={characterData.residence} onChange={(e)=>{setCharacterData(prev => ({...prev, residence: e.target.value}))}}/>
                      :
                        <Typography>
                          {characterData.residence}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
  
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
  )
}

export default CharacterBox
