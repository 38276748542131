import React, { useContext } from 'react'
import { ApiContext } from './hooks/HellApi'
import { useNavigate } from 'react-router-dom';

function Admin() {
    const {userData} = useContext(ApiContext);
    const navigate = useNavigate();

    if (!userData) return;
    if (!userData.scopes.split(",").includes("admin")) navigate("/");
  return (
    <div>
      Hallu
    </div>
  )
}

export default Admin
