import { ArrowCircleDown, CenterFocusStrong, Delete, Edit, Send } from '@mui/icons-material';
import { Avatar, Box, Button, CircularProgress, Divider, Icon, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, TextField, Typography, useTheme } from '@mui/material';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { ApiContext } from './hooks/HellApi';
import { data, useNavigate } from 'react-router-dom';

const text = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.";

function Feed() {
  const [input, setInput] = useState("");
  const theme = useTheme();
  
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const {get, post, getSocket, socketUpdate, getBasePath} = useContext(ApiContext);
  const getting = useRef();
  const containerRef = useRef(null);
  const [selectedChar, setSelectedChar] = useState("");
  const [myChars, setMyChars] = useState([]);
  const [charSelectorOpen, setCharSelectorOpen] = useState(false);

  useEffect(()=>{
    getMyChars();
  }, []);

  async function getMyChars(){
    const result = await get("/characters/mine");
    if (result.success){
      setMyChars(result.response.data);
      setSelectedChar(result.response.data[0]);
    }
  }

  // Simuliert das Nachladen älterer Nachrichten
  const loadMoreMessages = async () => {
    if (getting.current) return;
    getting.current = true;
    if (endReached || loading) return;
    setLoading(true);

    const result = await get(`/feed?from=${messages.length>0?messages[messages.length-1].id:""}`);

    if (!result.success) return;
    setMessages(prev => ([...prev, ...result.response.data.messages]));
    if (result.response.data.end){
      setMessages(prev => ([...prev, {text: "end"}]));
      setEndReached(true);
    }
    setLoading(false);
    getting.current = false;
    return;
  };

  // Überwacht das Scrollen und lädt Nachrichten, wenn man oben ankommt
  const handleScroll = () => {
    const { scrollTop, scrollHeight, offsetHeight, clientHeight } = containerRef.current;

    //if (scrollTop*-1 > 9000) alert(scrollTop + " | "+ clientHeight +" | "+ scrollHeight);

    // Wenn der Benutzer das Ende des Containers erreicht (scrollTop + clientHeight === scrollHeight)
    if ((Math.round(scrollTop*-1) + clientHeight) >= scrollHeight-10) {
      loadMoreMessages(); // Neue Nachrichten nach unten hinzufügen
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    //handleScroll();
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messages]);

  // Initiales Laden der ersten Nachrichten
  useEffect(() => {
    loadMoreMessages();
  }, []);

  useEffect(()=>{
    const channel = getSocket("/socket");
    if (channel){
        channel.on("json", handleSocket);
      return function(){
        channel.off("json", handleSocket)
      }
    }
  }, [socketUpdate]);

  function handleSocket(data){
    if (data.event === "feed"){
      setMessages(prev => ([data.data, ...prev]));
    }else if (data.event === "feedDelete"){
      setMessages(prev => (prev.filter(message => message.id !== data.data.id)))
    }else if (data.event === "feedUpdate"){
      setMessages(prev => prev.map(message => {
        if (message.id === data.data.id){
          return data.data;
        }else{
          return message;
        }
      }))
    }
  }

  async function postMessage(){
    const result = await post("/feed", {message: input, charid: selectedChar.id});
    if (containerRef.current){
      containerRef.current.scrollTo({top: 0, behavior: "smooth"});
      setInput("");
    }
  }

  return (
    <Box sx={{ position: "relative", display: "flex", flexDirection: "column", flexGrow: 1, width: {md:"50%",xs: "100%"}, m: "auto", height: "1px", overflow: "hidden",}}>
      <Modal open={charSelectorOpen}>
          <List component={Paper} sx={{position: "absolute", top: "50%", left: "50%",                 transform: 'translate(-50%, -50%)',
              width: 400,}} disablePadding>
            {myChars.map(char=>
              <ListItemButton onClick={()=>{
                setSelectedChar(char);
                setCharSelectorOpen(false);
              }}>
                <ListItemIcon>
                <Avatar src={`${getBasePath()}/images/character/${char.id}`}/>
                </ListItemIcon>
                <ListItemText primary={`${char.name} "${char.nick}" ${char.surname}`}/>
              </ListItemButton>

            )}
          </List>
      </Modal>
      <Box 
        ref={containerRef} 
        sx={{ 
          display: "flex", 
          flexDirection: "column-reverse", 
          gap: 2, 
          overflow: "scroll", 
          p: 2, 
          maxHeight: "100vh",
          flexGrow: 1
        }}>
        {
          loading&&
          <CircularProgress sx={{m: "auto", position: "absolute", top: 0, left: 0, right: 0}}/>
        }
        {messages.map((e, index) => (
          <FeedMessage key={index+e.date} myChars={myChars} editable={myChars.find(char => char.id === e.characterId)} id={e.id} text={e.text} date={e.date} characterId={e.characterId} characterName={e.characterName}/>
        ))}
      </Box>
      <Box sx={{ m: "auto", mt: 0, width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
          {false&&<Paper sx={{m: "auto", width: "fit-content", mb: 1}}>
          <IconButton size='large' onClick={()=>{
            if (containerRef.current){
              containerRef.current.scrollTo({top: 0, behavior: "smooth"});
            }
          }}>
            <ArrowCircleDown sx={{color: theme.palette.primary[300]}} fontSize='large'/>
          </IconButton>
          </Paper>}
        <Paper elevation={24} sx={{ display: "flex", m: { md: "auto", xs: 0 }, p: 2, gap: 1, width: "100%" }}>
          <Avatar src={`${getBasePath()}/images/character/${selectedChar.id}`} sx={{":hover": {opacity: "50%", cursor: "pointer"}, my: "auto"}} onClick={()=>{setCharSelectorOpen(true)}}/>
          <TextField 
            fullWidth 
            placeholder='Deine Geschichte...' 
            size='small' 
            maxRows={8}
            multiline 
            value={input} 
            onChange={(e) => setInput(e.target.value)} 
          />
          <Button variant="contained" sx={{ height: "fit-content", my: "auto" }} endIcon={<Send />} onClick={()=>{postMessage()}}>
            Send
          </Button>
        </Paper>
      </Box>
    </Box>
  );
}

function FeedMessage({id, text, characterId, characterName, date, editable, myChars}){

  const theme = useTheme();
  const {getBasePath, post} = useContext(ApiContext);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [updatedText, setUpdatedText] = useState(text);
  const [updatedCharId, setUpdatedCharId] = useState(characterId);
  const navigate = useNavigate();

  async function deleteThis(){
    await post("/feed/deleteMessage", {id});
    setDeletionModalOpen(false);
  }

  async function updateThis(){
    await post("/feed/update", {id, message: updatedText, charid: updatedCharId});
    setEditModalOpen(false);
  }
  
  if (text==="end")
    return (
      <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
        <Paper elevation={12} sx={{p: 1}}>
          <Typography variant='h6' sx={{textAlign: "center"}}>
            Start des Feeds
          </Typography>
          <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}}/>
        </Paper>
      </Box>
    )

  return (
    <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
      <Modal open={editModalOpen}>
        <Paper sx={{position: "absolute", top: "50%", left: "50%",transform: 'translate(-50%, -50%)',width: 400, p: 1}}>
          <List>
          {myChars.map(char=>
          <ListItemButton sx={{opacity: updatedCharId === char.id?"50%":"100%"}} onClick={()=>{setUpdatedCharId(char.id)}}>
            <ListItemIcon>
              <Avatar src={`${getBasePath()}/images/character/${char.id}`} sx={{":hover": {opacity: "50%", cursor: "pointer"}, my: "auto"}}/>
            </ListItemIcon>
            <ListItemText primary={`${char.name} "${char.nick}" ${char.surname}`}/>
          </ListItemButton>
        )}
          </List>
        <TextField 
            fullWidth 
            placeholder='Deine Geschichte...' 
            size='small' 
            maxRows={10}
            sx={{mb: 1.5}}
            multiline 
            value={updatedText} 
            onChange={(e) => setUpdatedText(e.target.value)}
          />
          <Box sx={{display: "flex", gap: 2}}>
            <Button variant='contained' color='error' fullWidth onClick={()=>{setEditModalOpen(false)}}>Abbrechen</Button>
            <Button variant='contained' color='success' fullWidth onClick={()=>{updateThis()}}>Speichern</Button>
          </Box>
        </Paper>
      </Modal>
      <Modal open={deletionModalOpen} onClose={()=>{setDeletionModalOpen(false)}}>
        <Paper sx={{position: "absolute", top: "50%", left: "50%",transform: 'translate(-50%, -50%)',width: 400, p: 1}}>
          <Typography sx={{textAlign: "center"}} variant='h6'>
            Diese Nachricht wirklich löschen?
          </Typography>
          <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%", mb: 1}}/>
          <Typography sx={{whiteSpace: "pre-line", mb: 2}}>
            {text}
          </Typography>
          <Box sx={{display: "flex", gap: 2}}>
            <Button variant='contained' color='error' fullWidth onClick={()=>{deleteThis()}}>Löschen</Button>
            <Button variant='contained' color='success' fullWidth onClick={()=>{setDeletionModalOpen(false)}}>Abbrechen</Button>
          </Box>
        </Paper>
      </Modal>
      <Paper elevation={12} sx={{p: 1}}>
        <Box onClick={()=>{
          navigate("/characters/"+characterId);
        }} sx={{display: "flex", mb: 1, flexDirection: "row", gap: 1, ":hover": {opacity: "50%", cursor: "pointer"}, width: "fit-content", px: 1}}>
          <Avatar src={`${getBasePath()}/images/character/${characterId}`}/>
          <Typography variant='h6' sx={{color: theme.palette.primary[300], my: "auto"}}>
            {characterName}
          </Typography>
        </Box>
        <Typography sx={{whiteSpace: "pre-line"}}>
          {text}
        </Typography>
        <Box sx={{display: "flex", alignContent: "center"}}>
          <Typography sx={{fontSize: 12, color: theme.palette.primary[300], mt: "auto"}}>
            {new Date(date).toLocaleString()}
          </Typography>
          {editable&&
            <Box sx={{p: 0, ml: "auto"}}>
              <IconButton size='small' sx={{p: 0}} onClick={()=>{setEditModalOpen(true)}}><Edit/></IconButton>
              <IconButton color='error' size='small' sx={{p: 0}} onClick={()=>{setDeletionModalOpen(true)}}><Delete/></IconButton>
            </Box>
          }
        </Box>
      </Paper>
    </Box>
  )
}

export default Feed;
