import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './Layout'
import { ApiContext, useAPI } from './hooks/HellApi'
import { Box, Button, createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import LandingPage from './LandingPage'
import Failback from './Failback'
import { SettingsContext } from './hooks/GlobalSettings'
import Settings from "./Settings";
import MyCharacter from './MyCharacter'
import { Css } from '@mui/icons-material'
import User from './User'
import Characters from './Characters'
import Admin from './Admin'
import Feed from './Feed'
import Updates from './Updates'


function Main() {

    const {isLoggedIn, setBasePath, userData, getSocket, connectSocket} = useContext(ApiContext);

    const [selectedTheme, setSelectedTheme] = useState("default");
    const [selectedFont, setSelectedFont] = useState("Lucida Console, Courier New, monospace");
    const [connectedUsers, setConnectedUsers] = useState([]);

    useEffect(()=>{
        if (!userData || !userData.store.theme){
          return;
        };
        setSelectedTheme(userData.store.theme);
        console.log("Switched theme to", userData.store.theme);
    }, [userData]);

    useEffect(()=>{
        if (!userData || !userData.store.font) {
          return;
        }
        setSelectedFont(userData.store.font);
        console.log("Switched to font", userData.store.font);
    }, [userData]);

    useEffect(()=>{
      if (userData){
        let socket = getSocket("/socket");
        if (!socket){
          socket = connectSocket("/socket");
        }
        socket.on("welcome", handleWelcome);
        socket.on("json", handleSocketEvents);
        return function(){
          socket.off("welcome", handleWelcome);
          socket.off("json", handleSocketEvents);
        }
      }
    }, [userData]);

    function handleSocketEvents(){}
    function handleWelcome(uuid){
      console.log("connected to socket, your id:", uuid);
    }

    useEffect(()=>{
        setBasePath("https://api.neopolis.pwcca.eu");
        //setBasePath("http://192.168.178.222:3000");
        //tryLogin();
    }, []);

    const hellish = createTheme({
      typography: {
        fontFamily: selectedFont
      },
      palette: {
        mode: "dark",
        primary: {
          main: "#ff4605",
          "500": "#ff591e",
          "400": "#ff6b37",
          "300": "#ff7e50",
          "200": "#ff9069",
          "100": "#ffa382",
          "50": "#ffb59b",
          "600": "#e63f05",
          "700": "#cc3804",
          "800": "#b33104",
          "900": "#190700"
        },
        secondary: {
          main: "#ffd729",
          "500": "#ffdb3e",
          "400": "#ffdf54",
          "300": "#ffe369",
          "200": "#ffe77f",
          "100": "#ffeb94",
          "50": "#ffefa9",
          "600": "#e6c225",
          "700": "#ccac21",
          "800": "#b3971d",
          "900": "#998119",
          contrastText: "#191504"
        },
        background: {
          default: "#000000",//"#290404",
          paper: "#450901"
        },
      }
    })

    const neopolis = createTheme({
        typography: {
            fontFamily: selectedFont
        },
        palette: {
          mode: "dark",
          primary: {
            main: "#ff2a6d",
            "500": "#ff3f7c",
            "400": "#ff558a",
            "300": "#ff6a99",
            "200": "#ff7fa7",
            "100": "#ff95b6",
            "50": "#ffaac5",
            "600": "#e62662",
            "700": "#cc2257",
            "800": "#b31d4c",
            "900": "#991941",
            contrastText: "#19040b"
          },
          secondary: {
            main: "#7700a6",
            "500": "#851aaf",
            "400": "#9233b8",
            "300": "#a04dc1",
            "200": "#ad66ca",
            "100": "#bb80d3",
            "50": "#c999db",
            "600": "#6b0095",
            "700": "#5f0085",
            "800": "#530074",
            "900": "#470064",
            contrastText: "#180021"
          },
          info: {
            main: "#0016ee",
            "500": "#1a2df0",
            "400": "#3345f1",
            "300": "#4d5cf3",
            "200": "#6673f5",
            "100": "#808bf7",
            "50": "#99a2f8",
            "600": "#0014d6",
            "700": "#0012be",
            "800": "#000fa7",
            "900": "#000d8f",
            contrastText: "#000b77"
          },
          success: {
            main: "#1afe49",
            "500": "#31fe5b",
            "400": "#48fe6d",
            "300": "#5ffe80",
            "200": "#76fe92",
            "100": "#8dffa4",
            "50": "#a3ffb6",
            "600": "#17e542",
            "700": "#15cb3a",
            "800": "#12b233",
            "900": "#10982c",
            contrastText: "#031907"
          },
          warning: {
            main: "#ffa500",
            "500": "#ffae1a",
            "400": "#ffb733",
            "300": "#ffc04d",
            "200": "#ffc966",
            "100": "#ffd280",
            "50": "#ffdb99",
            "600": "#e69500",
            "700": "#cc8400",
            "800": "#b37300",
            "900": "#996300",
            contrastText: "#191000"
          },
          error: {
            main: "#ff073a",
            "500": "#ff204e",
            "400": "#ff3961",
            "300": "#ff5175",
            "200": "#ff6a89",
            "100": "#ff839d",
            "50": "#ff9cb0",
            "600": "#e60634",
            "700": "#cc062e",
            "800": "#b30529",
            "900": "#990423",
            contrastText: "#190106"
          },
          background: {
            default: "#02060e",
            paper: "#2A3056"
          },
        }
      })
      
      const theme2 = createTheme({
        palette: {
          mode: "dark",
        },
        typography: {
            fontFamily: selectedFont
        },
      })
      
      const themes = {
        "default": neopolis,
        "dark": theme2,
        "hellish": hellish
      }


    if (!isLoggedIn){
        return (
          <ThemeProvider theme={themes[selectedTheme]}>
            <CssBaseline/>
            <LandingPage/>
          </ThemeProvider>
        )
    }else{
        return (
                <ThemeProvider theme={themes[selectedTheme]}>
                    <CssBaseline/>
                    <BrowserRouter>
                        <Routes>
                            <Route path='/' element={<Layout/>}>
                                <Route index element={<Feed/>}/>
                                <Route path='/settings' element={<Settings/>}/>
                                <Route path="/myCharacter/*" element={<MyCharacter/>}/>
                                <Route path='/user/*' element={<User/>}/>
                                <Route path="/characters/*" element={<Characters/>}/>
                                <Route path='/updates' element={<Updates/>}/>
                                <Route path='/admin' element={<Admin/>}/>
                                <Route path='*' element={<Failback/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>          
          )
    }
}

export default Main