import {EventEmitter} from "events";
import { createContext, useEffect, useState } from "react";

class AppHandler extends EventEmitter {
    #basePath;

    socketChannels = {};

    // eslint-disable-next-line
    constructor(){
        super();
        this.socketChannels = {};
    }

    setBasePath(path){
        this.#basePath = path;
    }

    get basePath(){
        return this.#basePath;
    }

    async isLoggedIn(){
        const result = await this.get("/hell/auth/check");
        if (!result.success) return false;
        return result.response.data==="logged-in";
    }

    async get(query="/"){
        try {
            const response = await fetch(this.#basePath+query, {
                method: "GET",
                credentials: "include"
            });
            const respData = await response.json();
            if (response.ok){
                return {success: true, response: respData};
            }else{
                return {success: false, response: respData};
            }
        } catch(e){
            return {success: false, response: "Internal Server Error"};
        }
    }

    async post(query="/", data={}){
        let formData = new FormData();
        for (let key in data){
            formData.set(key, data[key]);
        }
        try {
            const response = await fetch(this.#basePath+query, {
                method: "POST",
                body: formData,
                credentials: "include"
            });
            const respData = await response.json();
            if (response.ok){
                return {success: true, response: respData};
            }else{
                return {success: false, response: respData};
            }
        } catch(e){
            return {success: false, response: "Internal Server Error"};
        }
    }

    async delete(query="/", data={}){
        let formData = new FormData();
        for (let key in data){
            formData.set(key, data[key]);
        }
        try {
            const response = await fetch(this.#basePath+query, {
                method: "DELETE",
                body: formData,
                credentials: "include"
            });
            const respData = await response.json();
            if (response.ok){
                return {success: true, response: respData};
            }else{
                return {success: false, response: respData};
            }
        } catch(e){
            return {success: false, response: "Internal Server Error"};
        }
    }

    connectChannel(channel){
        if (this.socketChannels[channel]) return false;
        this.socketChannels[channel] = new Socket(this.#basePath, channel);
        return this.socketChannels[channel];
    }

    getChannel(channel){
        return this.socketChannels[channel];
    }

    deleteChannel(channel){
        if (!this.socketChannels[channel]) return;
        delete this.socketChannels[channel];
    }

    destoryAllSocketConnections(){
        for (let key in this.socketChannels){
            this.socketChannels[key].destroy();
        }
    }

}

const API = new AppHandler();

class Socket extends EventEmitter {
    connection;
    #tryRecon = false;
    #connection_id;
    #basePath;
    #channel

    constructor(basePath, channel){
        super();
        this.#basePath = basePath.replace("http","ws").replace("https", "wss");
        this.#channel = channel;
        this.#connect();
        this.setMaxListeners(0);
    }

    get basePath(){
        return this.#basePath;
    }

    async #connect(){
        let wasReconTry = this.#tryRecon;
        const result = await API.post("/auth/websocket"+this.#channel);
        if (result.success){
            const connectionToken = result.response.data.ConnectionToken.token;
            
            this.#tryRecon = false;
            this.connection = new WebSocket(this.#basePath+this.#channel+"?connection_token="+connectionToken);
            this.connection.onmessage = (e) => {
                try {
                    let json = JSON.parse(e.data);
                    if (json.event === "welcome"){
                        this.emit("connected", json.data.UUID);
                        this.#connection_id = json.data.UUID;
                        return;
                    }
                    this.emit("json", json);
                } catch(err){
                    this.emit("plain", e.data);
                }
            }
            this.connection.onclose = (e) => {
                this.connection_id = undefined;
                this.emit("closed", {wasClean: e.wasClean, code: e.code, reason: e.reason});
                if (!e.wasClean){
                    this.#tryReconnect(5000);
                    return;
                }
            }
            this.connection.onopen = (e) => {
                if (wasReconTry){
                    this.emit("reconnected");
                }
            }
        }
    }

    get connection_id(){
        return this.#connection_id;
    }

    set connection_id(newId){
        this.#connection_id = newId;
    }

    #tryReconnect(timeout){
        if (this.#tryRecon) return;
        this.#tryRecon = true;
        setTimeout(()=>{
            this.#connect();
        }, timeout);
    }

    send(event, payload){
        if (!this.connection){
            setTimeout(()=>{
                if (this.send){
                    this.send(event, payload);
                }
            }, 500);
            return;
        }
        this.connection.send(JSON.stringify({event, payload}));
    }

    destroy(){
        this.#tryRecon = true;
        this.connection.close();
        for (let event of this.eventNames()){
            this.removeAllListeners(event);
        }
        API.deleteChannel(this.#channel);
    }
}

export const ApiContext = createContext();

export const ApiProvider = ({children}) => {
    const [socketUpdate, setSocketUpdate] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserdata] = useState(undefined);

    useEffect(()=>{
        if (!isLoggedIn) return;
        getUserData();
    }, [isLoggedIn]);

    useEffect(()=>{
        if (!isLoggedIn){
            API.destoryAllSocketConnections();
        }
    }, [isLoggedIn]);

    function setBasePath(path){
        API.setBasePath(path);
        checkLoginStatus();
    }

    function getBasePath(){
        return API.basePath;
    }

    async function checkLoginStatus(){
        setIsLoggedIn(await API.isLoggedIn());
    }

    async function login(username, password){
        const result = await API.post("/auth/login", {username, password});
        if (result.response && (result.response.data === "login-success" || result.response.data === "already-in")){
            setIsLoggedIn(true);
            return {success: true, result: "logged-in"};
        }
        setIsLoggedIn(false);
        if (result.success){
            return {success: false, result: result.response.data};
        }else{
            return {success: false, result: result.response.status_string};
        }
    }

    async function logout(){
        await API.post("/auth/logout");
        setIsLoggedIn(false);
    }

    async function getUserData(){
        if (!isLoggedIn) return;
        const result = await API.get("/users/me");
        if (result.success){
            setUserdata(result.response.data);
        }
    }

    function connectSocket(channel){
        setSocketUpdate(prev => prev+1);
        return API.connectChannel(channel);
    }

    function getSocket(channel){
        return API.getChannel(channel);
    }

    async function get(query){
        return await API.get(query);
    }

    async function post(query, data={}){
        return await API.post(query, data);
    }

    async function delet(query, data={}){
        return await API.delete(query, data);
    }

    return (
        <ApiContext.Provider value={{isLoggedIn, socketUpdate, setBasePath, getBasePath, login, logout, userData, updateUserData: getUserData, get, post, delet, connectSocket, getSocket}}>
            {children}
        </ApiContext.Provider>
    )
}