import { Divider, Paper, Typography, useTheme } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom'
import Debug from './components/Debug';

function User() {
    const location = useLocation();
    const theme = useTheme();
  return (
    <>
        <Paper elevation={12} sx={{m: "auto", width: "fit-content", p:2, mb: 2}}>
            <Typography variant='h6' sx={{textAlign: "center"}}>
                Du hast eine Nutzerseite aufgerufen. Diese Seite(n) befinden sich noch im Aufbau.
            </Typography>
            <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3}}/>
        </Paper>
        <Debug/>
    </>
  )
}

export default User
