import { Box, Button, Divider, Modal, Paper, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import { ApiContext } from '../hooks/HellApi';
import { useTheme } from '@emotion/react';

function NewCharModal({open, setOpen, setCharData}) {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const {post} = useContext(ApiContext);
    const theme = useTheme();

    async function createNewChar(){
        const result = await post("/newChar", {name, surname});
        if (result.success){
          setCharData(result.response.data);
          setOpen(false);
          setName("");
          setSurname("");
        }
      }
  return (
    <Modal open={open}>
        <Paper elevation={12} sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
        }}>
            <Typography variant='h5' sx={{textAlign: "center"}}>
                Neuen Charakter erstellen
            </Typography>
            <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%", mb: 2}}/>
            <TextField fullWidth value={name} onChange={(e)=>{setName(e.target.value)}} placeholder='Vorname' sx={{mb: 1}}/>
            <TextField fullWidth value={surname} onChange={(e)=>{setSurname(e.target.value)}} placeholder='Nachname'/>
            <Typography variant="p" sx={{opacity: "70%", color: theme.palette.primary[200], mt: 1}}>
                Weitere Infos können nach der Erstellung im Charakterblatt bearbeitet werden
            </Typography>
            <Box sx={{display: "flex", justifyContent: "center", gap: 4, mt: 1.5}}>
                <Button fullWidth variant='contained' color='error' onClick={()=>{setOpen(false)}}>Schließen</Button>
                <Button disabled={!surname || !name} fullWidth variant='contained' color='success' onClick={()=>{createNewChar()}}>Erstellen</Button>
            </Box>
        </Paper>
    </Modal>
  )
}

export default NewCharModal