import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './Main';
import { ApiProvider } from './hooks/HellApi';
import { SettingsProvider } from './hooks/GlobalSettings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ApiProvider>
        <SettingsProvider>
          <Main/>
        </SettingsProvider>
      </ApiProvider>
);
