import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NeopolisLogo from './components/Neopolis_Logo';
import { ApiContext } from './hooks/HellApi';
import { Add, AdminPanelSettings, AdminPanelSettingsOutlined, ArrowCircleDown, ArrowCircleRight, ArrowCircleRightOutlined, ArrowDownwardOutlined, ArrowDropDownCircle, ArrowRightAltOutlined, ArrowRightOutlined, ArrowUpwardOutlined, BookOnline, Contacts, CreateOutlined, DynamicFeed, DynamicFeedOutlined, ExpandCircleDown, ExpandCircleDownOutlined, FeedOutlined, Forum, ForumOutlined, LogoutOutlined, OnlinePredictionRounded, People, PeopleAltOutlined, Person, Person2, Person2Outlined, Settings, SettingsOutlined, StarBorder, SupervisorAccount, SupervisorAccountOutlined, Update, UpdateOutlined, VerifiedUser } from '@mui/icons-material';
import Background from "./background.jpg"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Collapse, Icon, ListItemAvatar, Paper } from '@mui/material';
import NewCharModal from './components/NewCharModal';

const links = [
  {
    path: "/",
    name: "Feed",
    icon: <Forum/>,
    icon_active: <ForumOutlined/>,
    required_scope: false,
  },
  {
    path: "/myCharacters",
    name: "Meine Charaktere",
    icon: <ArrowDropDownCircle/>,
    icon_active: <ArrowDownwardOutlined/>,
    required_scope: false,
    charAccordion: true,
  },
  {
    path: "/characters",
    name: "Charaktere",
    icon: <SupervisorAccount/>,
    icon_active: <SupervisorAccountOutlined/>,
    required_scope: false,
  },
  {
    path: "/settings",
    name: "Einstellungen",
    icon: <Settings/>,
    icon_active: <SettingsOutlined/>,
    required_scope: false,
  },
  {
    path: "/updates",
    name: "Updates",
    icon: <Update/>,
    icon_active: <UpdateOutlined/>,
    required_scope: false
  },
  {
    path: "/admin",
    name: "Admin",
    icon: <AdminPanelSettings/>,
    icon_active: <AdminPanelSettingsOutlined/>,
    required_scope: "admin"
  }
]


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const {logout, userData, getSocket, socketUpdate, get, getBasePath, post} = React.useContext(ApiContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [onlineUsers, setOnlineUsers] = React.useState([]);
  const [myChars, setMyChars] = React.useState([]);
  const [openNewCharModal, setOpenNewCharModal] = React.useState(false);


  const reportRef = React.useRef();
  React.useEffect(()=>{
    if (userData){
      let socket = getSocket("/socket");
      if (!socket) {
        return;
      }
      reportConnected();
      socket.on("json", handleSocketEvents);
      return function(){
        socket.off("json", handleSocketEvents);
        clearInterval(reportRef.current);
      }
    }
  }, [userData, socketUpdate]);

  React.useEffect(()=>{
    getMyChars();
  }, []);

  async function getMyChars(){
    const result = await get("/characters/mine");
    if (result.success){
      setMyChars(result.response.data);
    }
  }

  function reportConnected(){
    let socket = getSocket("/socket");
    if (!socket) return clearInterval(reportRef.current);
    socket.send("reportConnected");

    reportRef.current = setInterval(()=>{
      socket.send("reportConnected");
    }, 5000);
  }

  function handleSocketEvents(eventData){
    if (eventData.event === "online"){
      let users = [...eventData.data];
      for (let i = 0; i < 10; i++){
        continue;
        users.push({
          id: i,
          username: "Sam"+i
        })
      }
      setOnlineUsers(users);
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [newCharModalOpen, setNewCharModalOpen] = React.useState(false);


  if (!userData) return;

  return (
    <Box sx={{ display: 'flex' }}>
      <NewCharModal open={newCharModalOpen} setOpen={setNewCharModalOpen} setCharData={setMyChars}/>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 0,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <NeopolisLogo/>
          <IconButton color='inherit' onClick={()=>{logout()}}
            edge="end"
          >
            <LogoutOutlined/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{mr: "auto", ml: 1, display: "flex", flexDirection: "row"}}>
            <Avatar src={Background}/>
            <Typography variant='h6'sx={{ml: 1,my: "auto"}}>Menu</Typography>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {links.filter(link=>!link.required_scope||userData.scopes.split(",").includes(link.required_scope)).map((obj, index) => (
            <>
            <ListItem key={obj.name} disablePadding sx={{ display: 'block', opacity: obj.path!=="/"&&location.pathname.startsWith(obj.path)
              ?
                "50%"
              :
                obj.path==="/"&&location.pathname==="/"
                  ?
                  "50%"
                  :
                  "100%" }}>
              <ListItemButton
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                        justifyContent: 'initial',
                      }
                    : {
                        justifyContent: 'center',
                      },
                ]}
                onClick={()=>{
                  if (obj.charAccordion) {
                    setDrawerOpen(!drawerOpen);
                    return;
                  };
                  navigate(obj.path);
                }}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: 'center',
                    },
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: 'auto',
                        },
                  ]}
                >
                  {obj.charAccordion?
                    drawerOpen?
                      <ArrowDownwardOutlined/>
                      :
                      <ArrowRightAltOutlined/>
                  :
                  obj.path!=="/"&&location.pathname.startsWith(obj.path)
                    ?
                      obj.icon_active
                    :
                      obj.path==="/"&&location.pathname==="/"
                        ?
                        obj.icon_active
                        :
                        obj.icon
                  }
                </ListItemIcon>
                <ListItemText
                  primary={obj.name}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </ListItemButton>
            </ListItem>
            {obj.charAccordion&&
            <Collapse in={drawerOpen} timeout="auto" unmountOnExit>
              <List component={Paper} disablePadding>
                {myChars.map(char=>
                <ListItemButton onClick={()=>{
                  navigate(`/myCharacter/${char.id}`)
                }} sx={[
                  {opacity: obj.path!=="/"&&location.pathname.startsWith(`/myCharacter/${char.id}`)
                    ?
                      "50%"
                    :
                      obj.path==="/"&&location.pathname==="/"
                        ?
                        "50%"
                        :
                        "100%"},
                  {
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                        justifyContent: 'initial',
                      }
                    : {
                        justifyContent: 'center',
                      },
                ]}>
                  <ListItemIcon sx={[{
                    minWidth: 0,
                    justifyContent: "center",
                  },                    open
                  ? {
                      mr: 3,
                    }
                  : {
                      mr: 'auto',
                    },
              ]}
              >
                    <Avatar sx={[
                      {
                        minWidth: 0,
                        justifyContent: "center",
                        width: 24,
                        height: 24

                      },
                    ]} src={`${getBasePath()}/images/character/${char.id}`}/>
                  </ListItemIcon>
                  <ListItemText
                  primary={char.name}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
                </ListItemButton>
                )}
                <ListItemButton onClick={()=>{setNewCharModalOpen(true)}} sx={[{
                  minHeight: 48,
                  px: 2.5,
                },
                  open
                  ? {
                    justifyContent: 'initial',
                  }
                : {
                    justifyContent: 'center',
                  },
                ]}>
                  <ListItemIcon sx={[{
                    minWidth: 0,
                    justifyContent: "center",
                  },                    open
                  ? {
                      mr: 3,
                    }
                  : {
                      mr: 'auto',
                    },
                ]}>
                  <Add/>
                </ListItemIcon>
                <ListItemText
                  primary={"Neuer Charakter"}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
                </ListItemButton>
              </List>
            </Collapse>
            }
            </>
          ))}
        </List>
        <Paper elevation={4} sx={{mt: "auto"}}>
          <ListItem disablePadding sx={{display: "block"}}>
            <ListItemButton disableRipple disableTouchRipple
              sx={[
                {
                  ":hover": {
                    cursor: "default",
                    backgroundColor: "inherit"
                  },
                  minHeight: 48,
                  px: 2.5
                },
                open ?
                {
                  justifyContent: "initial",
                }
                :
                {
                  justifyContent: "center"
                }
              ]}
            >
              <ListItemIcon
                sx={[
                  {
                    minWidth: 0,
                    justifyContent: "center",

                  },
                  open ?
                  {
                    mr: 3
                  }
                  :
                  {
                    mr: "auto"
                  }
                ]}
              >
                <Contacts/>
              </ListItemIcon>
              <ListItemText
                primary={"Online"}
                sx={[
                  open
                    ? {
                        opacity: 1,
                      }
                    : {
                        opacity: 0,
                      },
                ]}
              />
            </ListItemButton>
          </ListItem>
          <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3}}/>
          <List sx={{mt: "auto", height: "150px", overflowY: "auto", overflowX: "hidden"}}>
            {onlineUsers.map((obj, index)=>(
              <ListItem key={obj.id} disablePadding sx={{display: "block", opacity: location.pathname.startsWith(`/user/${obj.id}`)?"50%":"100%"}}>
                <ListItemButton
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5
                    },
                    open ?
                    {
                      justifyContent: "initial",
                    }
                    :
                    {
                      justifyContent: "center"
                    }
                  ]}
                  onClick={()=>{
                    navigate(`/user/${obj.id}`);
                  }}
                >
                  <Avatar
                    src={Background}
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: "center",
                        width: 32,
                        height: 32

                      },
                      open ?
                      {
                        mr: 3
                      }
                      :
                      {
                        mr: "auto"
                      }
                    ]}
                  />
                  <ListItemText
                    primary={userData.username === obj.username?`${obj.username} (Ich)`:obj.username}
                    sx={[
                      open
                        ? {
                            opacity: 1,
                          }
                        : {
                            opacity: 0,
                          },
                    ]}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Drawer>
      <Box component="main" sx={{ display: "flex", flexDirection: "column", flexGrow: 1, p: {xs: 1, md: 3}, backgroundImage: `url(${Background})`, overflow: "none", minHeight: "100vh", backgroundSize: "cover", backgroundAttachment: "fixed" }}>
        <DrawerHeader/>
        <Outlet/>
      </Box>
    </Box>
  );
}