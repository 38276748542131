import { Avatar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect, useContext, useRef } from 'react'
import Logo from '../background.jpg';
import CharacterBox from './CharacterBox';
import { ApiContext } from '../hooks/HellApi';
import { Navigate, useNavigate } from 'react-router-dom';
import { AutoAwesome } from '@mui/icons-material';

function Character({editable, minimal, id}) {
    const theme = useTheme();
    const [characterData, setCharacterData] = useState();
    const [savingData, setSavingData] = useState();
    const [saving, setSaving] = useState(false);
    const {get, post, getSocket, socketUpdate} = useContext(ApiContext);
    const [modelDialogOpen, setModelDialogOpen] = useState(false);
    const [modelResponse, setModelResponse] = useState("");
    const [channel, setChannel] = useState(false);
    const navigate = useNavigate();

    const autoSaveRef = useRef();

  useEffect(()=>{
    getChar();
  }, [id]);

  useEffect(()=>{
    if (characterData !== savingData){
        tryAutoSave();
    }
  }, [characterData]);

  useEffect(()=>{
    return function(){
        clearTimeout(autoSaveRef.current);
    };
  }, []);

  async function getChar(){
    const charResult = await get(`/character/${id}`);
    if (charResult.success){
        setCharacterData(charResult.response.data);
        setSavingData(charResult.response.data);
    }
  }

  async function trySave(){
    setSaving(true);
    const result = await post("/character", {charData: JSON.stringify(characterData)});
    if (result.success){
        setCharacterData(result.response.data);
        setSavingData(result.response.data);
        setModelResponse("");
    }
    setSaving(false);
  }

  async function tryAutoSave(){
    if (autoSaveRef.current) clearTimeout(autoSaveRef.current);
    autoSaveRef.current = setTimeout(()=>{
        trySave();
    }, 1500);
  }

  useEffect(()=>{
    const channel = getSocket("/socket");
    if (!channel) return;

    channel.on("json", getGenerationPart);
    setChannel(channel);

    return function(){
      channel.off("json", getGenerationPart);
    }
  }, [socketUpdate]);

  function getGenerationPart(json){
    if (json.event!=="generationPart") return;
    if (json.data !== "###END###" && json.data !== "###START###"){
      setModelResponse(prev => prev + json.data);
    }
  }

  if (!characterData) return;

  if (minimal){
    return (
        <CharacterBox editable={editable} minimal={minimal} characterData={characterData} setCharacterData={setCharacterData}/>
    )
  }
    return (
      <Box sx={{display: "flex", flexDirection: {md: "row", xs: "column"}}}>

        <Dialog open={modelDialogOpen} onClose={()=>{
            setModelDialogOpen(false);
          }}>
          <DialogTitle sx={{m: "auto"}}>
            Dein KI-Modell stellt sich vor
            <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}}/>
          </DialogTitle>
          <DialogContent>
            {!modelResponse?
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <CircularProgress sx={{m: "auto", mb: 2}}/>
              <Typography>Bitte warte, dein Modell wird antrainiert. Dies kann ein paar Minuten dauern, bitte schließe nicht das Fenster</Typography>
            </Box>
            :
            <>
              <Paper elevation={4} sx={{p: 1}}>
                <Typography>
                  {modelResponse}
                </Typography>
              </Paper>
              <Button sx={{mt: 2}} onClick={()=>{setModelDialogOpen(false)}}>Schließen</Button>
            </>
            }
          </DialogContent>
        </Dialog>

        <Box sx={{display: "flex", flexDirection: "column"}}>
            <CharacterBox characterData={characterData} setCharacterData={setCharacterData} editable={editable}/>
            {editable&&<Paper elevation={12} sx={{p: 2, mt: 2, mx: {xs: 0, md: 1}, display: "flex", flexDirection: "column", gap: 2, width: "fit-content", mx: "auto"}}>
                <Button color='success' size='small' variant='contained' disabled={savingData===characterData||saving} onClick={()=>{
                    trySave()
                }}>{savingData===characterData?`Gespeichert`:saving?"Speichert...":"Speichern"}</Button>
                <Button variant='contained' size='small' onClick={()=>{
                    navigate(`/characters/${characterData.id}`);
                }}>
                    Ansehen aus Sicht anderer
                </Button>
                <Button color='info' sx={{color: "red"}} size='small' variant='contained' startIcon={<AutoAwesome/>} onClick={()=>{
                  setModelDialogOpen(true);
                  if (!modelResponse){
                    channel.send("train");
                  }
                }} disabled={modelDialogOpen}>KI-Modell trainieren</Button>
            </Paper>}
        </Box>
        <Box sx={{ml: {md: 1, xs: 0}, mt: {xs: 2, md: 0, display: "flex", flexDirection: "column", flexGrow: 1}}}>
        <Box sx={{display: "flex", flexDirection: {md: "row", xs: "column"}}}>
            <Paper elevation={12} sx={{p: 2, mx: {xs: 0, md: 1}, width: {md:"40%", xs: "100%"}, mb: {md: 0, xs: 2}, minWidth: {md:"40%",xs: "100%"}, height: "fit-content"}}>
                <Typography variant='h6' sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                  Biografie
                </Typography>
                <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}}/>
                {editable?
                  <TextField hiddenLabel size='small' margin='none' variant='filled' multiline fullWidth value={characterData.biography} onChange={(e)=>{setCharacterData(prev => ({...prev, biography: e.target.value}))}}/>            
                :
                  <Typography sx={{whiteSpace: "pre-line"}}>
                    {characterData.biography}
                  </Typography>
                }
              </Paper>
            <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
              <Box sx={{display: "flex", flexDirection: {md: "row", xs: "column"}}}>
                <Paper elevation={12} sx={{p: 2, mx: {xs: 0, md: 1}, flexGrow: 1, height: "fit-content", width: "100%", mb: {md: 0, xs: 2}}}>
                  <Typography variant='h6' sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                    Familie
                  </Typography>
                  <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}} />
                  {editable?
                    <TextField hiddenLabel size='small' margin='none' variant='filled' multiline fullWidth value={characterData.family} onChange={(e)=>{setCharacterData(prev => ({...prev, family: e.target.value}))}}/>            
                  :
                    <Typography sx={{whiteSpace: "pre-line"}}>
                      {characterData.family}
                    </Typography>
                  }
                </Paper>
                <Paper elevation={12} sx={{p: 2, mx: {xs: 0, md: 1}, flexGrow: 1, height: "fit-content", width: "100%"}}>
                  <Typography variant='h6' sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                    Sonstiges
                  </Typography>
                  <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}}/>
                  {editable?
                    <TextField hiddenLabel size='small' margin='none' variant='filled' multiline fullWidth value={characterData.misc} onChange={(e)=>{setCharacterData(prev => ({...prev, misc: e.target.value}))}}/>            
                  :
                    <Typography sx={{whiteSpace: "pre-line"}}>
                      {characterData.misc}
                    </Typography>
                  }
                </Paper>
              </Box>
              {editable &&
              <>
                <Paper elevation={12} sx={{p: 2, mt: 2, mx: {xs: 0, md: 1}, width: "100%"}}>
                    <Typography variant='h6' sx={{fontWeight: "bold", color: theme.palette.primary[300]}}>
                    Privates (Nur für dich sichtbar)
                    </Typography>
                    <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}}/>
                    {editable?
                        <TextField sx={{flex: 1}} hiddenLabel size='small' margin='none' variant='filled' multiline fullWidth value={characterData.privat} onChange={(e)=>{setCharacterData(prev => ({...prev, privat: e.target.value}))}}/>            
                    :
                    <Typography sx={{whiteSpace: "pre-line"}}>
                        {characterData.privat}
                    </Typography>
                    }
                </Paper>
              </>
              }
            </Box>
          </Box>
        </Box>
    </Box>
    )
  }
  

export default Character
