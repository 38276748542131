import { Box, useTheme } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ApiContext } from '../hooks/HellApi';

function NeopolisLogo() {
    const theme = useTheme();
    const {userData} = useContext(ApiContext);

    const [color, setColor] = useState("black");
    const [textColor, setTextColor] = useState(theme.palette.primary[900]);
    const [offset, setOffset] = useState(0);

    const randomTimeoutRef = useRef();
    const flickerTimeoutRef = useRef();

    useEffect(()=>{
        if (!userData){
            randomizeLogo();
        }else{
            if (userData.store.disableMalfunktion){
                setColor(theme.palette.primary[100]);
                setTextColor(theme.palette.primary[500]);
            }else{
                randomizeLogo();
            }
        }
        if (!userData||!userData.store.disableFlicker){
            flickerOffset();
        }else{
            setOffset(0);
        }

        return () => {
            clearTimeout(randomTimeoutRef.current);
            clearTimeout(flickerTimeoutRef.current);
        }
    }, [theme, userData]);

    function randomizeLogo(){
        const chance = Math.floor(Math.random()*100);
        if (chance>8){
            setColor(theme.palette.primary[100]);
            setTextColor(theme.palette.primary[500]);
        }else{
            setColor("black");
            setTextColor(theme.palette.primary[900]);
        }
        clearTimeout(randomTimeoutRef.current);
        randomTimeoutRef.current = setTimeout(randomizeLogo, Math.floor(Math.random() * 2500) + 500);
    }

    function flickerOffset(){
        const offset = 7-(Math.floor(Math.random()*14));
        setOffset(offset);
        clearTimeout(flickerTimeoutRef.current);
        flickerTimeoutRef.current = setTimeout(flickerOffset, Math.floor(Math.random()*100)+100)
    }

  return (
    <Box sx={{
        transition: "0.2s",
        margin: "auto",
        width: "fit-content",
        marginBottom: 2,
        fontFamily: "neopolis",
        color: textColor,
        fontSize: 25,
        opacity: `${86+(offset*2)}%`,
        textShadow: `
            0 0 ${10+offset}px ${color}, 
            0 0 ${20+offset}px ${color}, 
            0 0 ${40+offset}px ${color}, 
            0 0 ${80+offset}px ${color}, 
            -1px -1px 0 ${color}, 
            1px -1px 0 ${color}, 
            -1px 1px 0 ${color}, 
            1px 1px 0 ${color};`,
    }}>
        Neopolis
    </Box>
  )
}

export default NeopolisLogo