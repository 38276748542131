import { Box, Divider, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'

const updates = [
    {
        title: "v0.5.0",
        text: `- First release
- Added Login/Register
- Added Settings
- Added Character creation
- Added Character view
- Added Online-list
        `
    },
    {
        title: "v0.5.1",
        text: `- Added model training`
    },
    {
        title: "v0.5.2",
        text: `- Added feed
- Recalculated containers
- Added Update notes (this page)
- Fixed some bugs
`
    },
    {
        title: "v0.6.0",
        text: `- Added functionality to use multiple characters
- Added possibility to update feed messages
- Added possibility to delete feed messages
- Fixed some bugs`
    }
]

function Updates() {
    const theme = useTheme();
  return (
    <Box sx={{width: {xs: "100%", md: "50%"}, m: "auto", flexGrow: 1}}>
        <Box sx={{display: "flex", flexDirection: "column-reverse", gap: 1}}>
            {updates.map(update=>
                <Paper elevation={12} sx={{p: 1}}>
                    <Typography variant='h4' sx={{textAlign: "center"}}>
                        {update.title}
                    </Typography>
                    <Divider sx={{borderBottomWidth: 3, borderColor: theme.palette.primary[300], opacity: "50%"}}/>
                    <Typography sx={{whiteSpace: "pre-line", mt: 2}}>
                        {update.text}
                    </Typography>
                </Paper>
            )}
        </Box>
    </Box>
  )
}

export default Updates
