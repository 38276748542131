import { useTheme } from '@mui/material';
import { Box, Button, Divider, List, ListItem, ListItemButton, Paper, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ApiContext } from '../hooks/HellApi';

function Debug() {
    const theme = useTheme();
    const location = useLocation();
    const {userData} = useContext(ApiContext);
    if (!userData) return;
  return (
    <Paper elevation={4} sx={{m: "auto", width: "fit-content", p:2, "& *":{fontFamily: "Arial, Helvetica, sans-serif"}}}>
        <Typography variant='h6' sx={{textAlign: "center"}}>
            Debug-Informations
        </Typography>
        <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 3}}/>
        <Typography sx={{textAlign: "center", mt: 2}}>
            Requested Path
        </Typography>
        <Typography sx={{textAlign: "center", color: theme.palette.primary[50]}}>
            {location.pathname}
        </Typography>
        <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 1}}/>
        <Typography sx={{textAlign: "center", mt: 2}}>
            My Username
        </Typography>
        <Typography sx={{textAlign: "center", color: theme.palette.primary[50]}}>
            {userData.username}
        </Typography>
        <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 1}}/>
        <Typography sx={{textAlign: "center", mt: 2}}>
            My ID
        </Typography>
        <Typography sx={{textAlign: "center", color: theme.palette.primary[50]}}>
            {userData.id}
        </Typography>
        <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 1}}/>
        <Typography sx={{textAlign: "center", mt: 2}}>
            My Scopes
        </Typography>
        <Typography sx={{textAlign: "center", color: theme.palette.primary[50]}}>
            {userData.scopes}
        </Typography>
        <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 1}}/>
        <Typography sx={{textAlign: "center", mt: 2}}>
            My Store
        </Typography>
        <Typography sx={{color: theme.palette.primary[50], whiteSpace: "pre-wrap", background: "rgba(150,150,150,0.1)", overflowX: "auto"}}>
            {JSON.stringify(userData.store, null, 2)}
        </Typography>
        <Divider sx={{borderColor: theme.palette.primary[300], borderBottomWidth: 1}}/>
    </Paper>
  )
}

export default Debug
