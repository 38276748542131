import { Avatar, Box, Button, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import Logo from './background.jpg';
import { Edit, Save } from '@mui/icons-material';
import Character from './components/Character';

import {ApiContext} from './hooks/HellApi';
import { useLocation } from 'react-router-dom';

const defaultCharData = {
  image: "",
  name: "Samael",
  surname: "Pwcca",
  nick: "Sam",
  age: 18,
  height: 180,
  eye_color: "red",
  hair_color: "black",
  job: "Gärtner",
  residence: "Untere Topside",
  biography: "",
  family: "",
  misc: "",
  private: "",
}

function MyCharacter() {
  const location = useLocation();
  return(
    <Character minimal={false} id={location.pathname.split("/")[location.pathname.split("/").length-1]} editable={true}/>
  )
}

export default MyCharacter