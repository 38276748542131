import { Alert, Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ApiContext } from './hooks/HellApi';
import { ErrorOutline } from '@mui/icons-material';
import NeopolisLogo from './components/Neopolis_Logo';

function LandingPage() {
    const [mode, setMode] = useState("login");

    return (
        <Paper elevation={12} sx={{p: 2, mt: 5, width: {md: "500px", xs: "full"}, mx: {md: "auto", xs: 2}, display: "flex", flexDirection: "column"}}>
            <NeopolisLogo primaryColor="#991941" secondaryColor="#ff3f7c" tertiaryColor="#ff95b6"/>
            {mode==="login"?<Login setMode={setMode}/>:<Register setMode={setMode}/>}
        </Paper>
    )
}

function Login({setMode}){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);

    const {login} = useContext(ApiContext);

    useEffect(()=>{
        setError(false);
    }, [username, password]);


    async function tryLogin(){
        const result = await login(username, password);
        if (!result.success){
            switch(result.result){
                default:
                    setError("Internal Server Error: Bitte gib Sam Bescheid, wenn das Problem weiterhin auftritt");
                    break;    
                case "already-in":
                    setError("Bug: Du solltest bereits eingeloggt sein. Bitte gib Sam Bescheid");
                    break;
                case "Bad_Request":
                    setError("Nutzername oder Passwort darf nicht leer sein");
                    break;
                case "username-password-wrong":
                    setError("Nutzername oder Passwort falsch");
                    break;
                case "not-activated":
                    setError("Dein Account wurde noch nicht freigeschaltet. Bitte Sam darum dies zu tun");
                    break;
                case "banned":
                    setError("Dein Account wurde gesperrt");
                    break;
            }
        }
    }

  return (
    <form action={tryLogin}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <TextField label="Username" variant='outlined' sx={{m:1}} error={error==="Nutzername oder Passwort falsch"} value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
            <TextField type='password' label="Passwort" variant='outlined' sx={{m:1}} error={error==="Nutzername oder Passwort falsch"} value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            {error&&<Alert icon={<ErrorOutline/>} severity='error' sx={{m: 1}}>{error}</Alert>}
            <Button type='submit' sx={{m: 1}} color='primary' disabled={!username || !password || error} variant='contained'>Login</Button>
            <Button size='small' sx={{ml: "auto", mr: 1, mt: 1}} color='primary' onClick={()=>{setMode("register")}}>
                Noch kein Account?
            </Button>
        </Box>
    </form>
  )
}

const REGEX_USERNAME = /^[a-zA-Z][a-zA-Z0-9._]{2,19}(?<![._])$/;    
const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()[\]{}<>?/\\|~`_=+.-]{6,}$/;                  // Min 6 characters & min one upperchar & min one lowerchar & min one number & allows special chars


function Register({setMode}){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [regCode, setRegCode] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setError(false);
    }, [username, password, passwordRepeat, regCode]);

    const {post, login} = useContext(ApiContext);

    async function tryRegister(){
        setLoading(true);
        const result = await post("/auth/register", {username, password, regCode});
        if (!result.success){
            if (!result.response.errorText){
                setError("Internal Server Error: Bitte gib Sam Bescheid, wenn das Problem weiterhin auftritt");
            }else{
                switch(result.response.errorText){
                    default:
                    case "email-exists":
                        setError("Internal Server Error: Bitte gib Sam Bescheid, wenn das Problem weiterhin auftritt");
                        break;
                    case "invalid_form":
                        setError("Fehler beim parsen des Formulars. Bitte gib Sam Bescheid");
                        break;
                    case "regCode_invalid":
                        setError("Einladungscode falsch!");
                        break;
                    case "username-exists":
                        setError("Dieser Nutzername existiert bereits");
                        break;
                    case "username-regex-fail":
                        setError("Dieser Nutzername ist invalid");
                        break;
                    case "password-regex-fail":
                        setError("Dieses Passwort ist invalid");
                        break;
                }
            }
        }else{
            await login(username, password);
        }
        setLoading(false);
    }

    const [error, setError] = useState(false);
    return (
    <form action={tryRegister}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <TextField label={!username?"Username":username.length<3?"Username (zu kurz)":!REGEX_USERNAME.exec(username)?"Username (Invalid)":"Useranme"} color={REGEX_USERNAME.exec(username)?"success":"error"} variant='outlined' sx={{m:1}} error={error==="Nutzername oder Passwort falsch"} value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
            <TextField type='password' color={REGEX_PASSWORD.exec(password)?"success":"error"} label={!password?"Passwort":password.length<6?`Passwort (${6-password.length} Zeichen zu kurz)`:!REGEX_PASSWORD.exec(password)?"Passwort (Nummer, Groß- oder Kleinbuchstabe fehlt)":"Passwort"} variant='outlined' sx={{m:1}} error={error==="Nutzername oder Passwort falsch"||(password&&!REGEX_PASSWORD.exec(password))} value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            <TextField type='password' color={passwordRepeat&&password===passwordRepeat?"success":"error"} label="Passwort wiederholen" error={passwordRepeat&&password!==passwordRepeat} variant='outlined' sx={{m:1}} value={passwordRepeat} onChange={(e)=>{setPasswordRepeat(e.target.value)}}/>
            <TextField label="Einladungscode" variant='outlined' sx={{m:1}} value={regCode} error={error==="Einladungscode falsch!"} onChange={(e)=>{setRegCode(e.target.value)}}/>
            {error&&<Alert icon={<ErrorOutline/>} severity='error' sx={{m: 1}}>{error}</Alert>}
            <Button type='submit' sx={{m: 1}} color='primary' disabled={loading || !username || !password || !regCode || (passwordRepeat !== password) || error} variant='contained'>Account erstellen</Button>
            <Button size='small' sx={{ml: "auto", mr: 1, mt: 1}} color='primary' onClick={()=>{setMode("login")}}>
                Zurück zum Login
            </Button>
        </Box>
    </form>
    )
}

export default LandingPage