import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Character from './components/Character';
import { Box } from '@mui/material';
import { ApiContext } from './hooks/HellApi';

function Characters() {
    const location = useLocation();

    const [charId, setCharId] = useState(false);

    useEffect(()=>{
        const id = location.pathname.split("/")[2]
        if (!id) setCharId(false);
        setCharId(id);
    }, [location]);

    if (charId){
        return <Character editable={false} id={charId} />
    }else{
        return <CharacterList/>
    }
}

function CharacterList(){
    const [characters, setCharacters] = useState([]);
    const {get} = useContext(ApiContext);

    useEffect(()=>{
        getCharacters();
    }, []);

    async function getCharacters(){
        const result = await get("/characters/list");
        if (result.success){
            setCharacters(result.response.data);
        }
    }
    return (
        <Box sx={{display: "flex", justifyContent: "center", flexDirection: "row", flexWrap: "wrap"}}>
            {characters.map((id,i)=>
                <Box sx={{m: {xs: 0, md: 1}, mb: {xs: 1, md: 0}}}>
                    <Character key={id+"meow"+i} id={id} minimal={true} />
                </Box>
            )}
        </Box>
    )
}

export default Characters
